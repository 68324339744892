import React, { Fragment } from 'react'
import { Modal, Form, Row, Col } from "react-bootstrap";

// import component
import AccountDetails from './AccountDetails';

// import action
import { changeState, trxInState } from '../../actions/tradeAction';

// import lib
import { toastAlert } from '../../lib/toastAlert';

class ViewTradeModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 'details', // details, complete
            trxId: '',
            euroReceived: '',
            megixoFee: '',
            exchangeRate: '',
            cryptoReceived: ''
        }
    }

    handleClose = () => {
        const { onHide } = this.props;
        onHide();
        this.setState({ 'page': 'details', 'trxId': '', 'euroReceived': '', 'megixoFee': '', 'exchangeRate': '', 'cryptoReceived': '' })
    }

    handleSubmit = async state => {
        try {
            const { fetchData, record } = this.props;
            let reqData = {
                'id': record._id,
                state,
                'trxId': this.state.trxId,
                transferDetails: {
                    'euroReceived': this.state.euroReceived,
                    'megixoFee': this.state.megixoFee,
                    'exchangeRate': this.state.exchangeRate,
                    'cryptoReceived': this.state.cryptoReceived
                }
            }
            const { status, loading, message, error } = await changeState(reqData);
            if (status == 'success') {
                fetchData();
                toastAlert('success', message, 'viewTrade');
                this.handleClose()
            } else {
                toastAlert('error', message, 'viewTrade');
            }
        } catch (err) {
        }
    }

    handleTransferIn = async () => {
        try {
            const { fetchData, record } = this.props;
            let reqData = {
                'id': record._id,
            }
            const { status, loading, message, error } = await trxInState(reqData);
            if (status == 'success') {
                fetchData();
                toastAlert('success', message, 'viewTrade');
                this.handleClose()
            } else {
                toastAlert('error', message, 'viewTrade');
            }
        } catch (err) {
        }
    }

    render() {
        const { isShow, record, viewRecord } = this.props;
        const { page, trxId, euroReceived, megixoFee, exchangeRate, cryptoReceived } = this.state
        return (
            <div>
                <Modal
                    show={isShow}
                    onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    size="lg"
                    centered
                    scrollable={false}
                >
                    <Modal.Header closeButton>
                        {
                            page == 'details' && <h4 className="modal-title">View Trade</h4>
                        }

                        {
                            page == 'complete' && <h4 className="modal-title">Transaction Details</h4>
                        }
                    </Modal.Header>

                    <Modal.Body>
                        {
                            page == 'details' && <Fragment>
                                <p>Are you sure. You want to change state?</p>
                                <AccountDetails record={record} viewRecord={viewRecord} />
                            </Fragment>
                        }

                        {
                            page == 'complete' && <Form>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="3">
                                        Transaction ID
                                    </Form.Label>
                                    <Col sm="9">
                                        <Form.Control
                                            name="trxId"
                                            value={trxId}
                                            onChange={(e) => {
                                                e.preventDefault()
                                                this.setState({ 'trxId': e.target.value })
                                            }}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Form.Label column sm="3">
                                        Euro Received:
                                    </Form.Label>
                                    <Col sm="9">
                                        <Form.Control
                                            name="euroReceived"
                                            value={euroReceived}
                                            onChange={(e) => {
                                                e.preventDefault()
                                                this.setState({ 'euroReceived': e.target.value })
                                            }}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Form.Label column sm="3">
                                        Atomiex Fee:
                                    </Form.Label>
                                    <Col sm="9">
                                        <Form.Control
                                            name="megixoFee"
                                            value={megixoFee}
                                            onChange={(e) => {
                                                e.preventDefault()
                                                this.setState({ 'megixoFee': e.target.value })
                                            }}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Form.Label column sm="3">
                                        Exchange Rate:
                                    </Form.Label>
                                    <Col sm="9">
                                        <Form.Control
                                            name="exchangeRate"
                                            value={exchangeRate}
                                            onChange={(e) => {
                                                e.preventDefault()
                                                this.setState({ 'exchangeRate': e.target.value })
                                            }}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Form.Label column sm="3">
                                        Crypto Received:
                                    </Form.Label>
                                    <Col sm="9">
                                        <Form.Control
                                            name="cryptoReceived"
                                            value={cryptoReceived}
                                            onChange={(e) => {
                                                e.preventDefault()
                                                this.setState({ 'cryptoReceived': e.target.value })
                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form>
                        }

                    </Modal.Body>

                    <Modal.Footer>

                        {
                            page == 'details' && <Fragment>
                                {
                                    record.state == 'awaiting_transfer_in' && <button
                                        onClick={this.handleTransferIn}
                                        className="btn btn-success"
                                    >
                                        Payment Receive
                                    </button>
                                }

                                {
                                    record.state == 'processing' && <Fragment>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => this.handleSubmit('rejected')}
                                        >
                                            Rejected
                                        </button>
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.setState({ 'page': 'complete' })
                                            }}
                                            className="btn btn-success"
                                        >
                                            Completed
                                        </button>
                                    </Fragment>
                                }
                            </Fragment>
                        }

                        {
                            page == 'complete' && record.state == 'processing' && <Fragment>
                                <button
                                    onClick={() => this.handleSubmit('completed')}
                                    className="btn btn-success"
                                >
                                    Completed
                                </button>
                            </Fragment>
                        }

                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default ViewTradeModal;