import React from 'react';
import { Document, Page, Font, Text, StyleSheet, View } from '@react-pdf/renderer';
import { getUserDetails} from "../actions/accountAction";

Font.register({
  family: 'Ubuntu',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/ubuntu/v9/OsJ2DjdpjqFRVUSto6IffKCWcynf_cDxXwCLxiixG1c.ttf',
      fontWeight: 700,
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Ubuntu',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1cm',
  },
  body: {
    paddingTop: '1cm',
    paddingBottom: '1cm',
    paddingHorizontal: '1cm',
  },
  section: {
    marginBottom: 10,
    padding: 10,
    flexGrow: 1,
    borderBottom: 1,
    borderColor: 'gray',
  },
  title: {
    fontSize: 18,
    marginBottom: 10,
    fontWeight: 700,
  },
  content: {
    fontSize: 13,
    marginBottom: 8,
    fontFamily: 'Ubuntu',
  },
  heading: {
    fontSize: 14,
    fontFamily: 'Ubuntu',
    marginBottom: 7,
  },
  bold: {
    fontWeight: 700,
    fontFamily: 'Ubuntu',
  },
  line: {
    borderTop: 1,
    marginTop: 10,
    marginBottom: 10,
    borderColor: 'gray',
  },
});



export const generatePDFContent = async(data) => {

  const response = await getUserDetails({
    id: data
  });
  let userData
  if(response && response.status === "success"){
    userData = response.result
  }else{
    userData = false
  }
  return (
    <Document>
     <Page size="A4" style={styles.body}>
        <View style={styles.body}>
          {/* Title */}
          <Text style={styles.title}>Atomiex Client details</Text>
          {/* Content */}
          <View style={styles.section}>
            <Text style={styles.content}>Email: <Text style={styles.bold}>{userData.email}</Text></Text>
            <Text style={styles.content}>Account Code: <Text style={styles.bold}>{userData.accountCode}</Text></Text>
            <Text style={styles.content}>Declaration State: <Text style={styles.bold}>{userData.declarationState}</Text></Text>
            <Text style={styles.content}>Identification  State: <Text style={styles.bold}>{userData.identificationState}</Text></Text>
            <Text style={styles.content}>First Name: <Text style={styles.bold}>{userData.firstName}</Text></Text>
            <Text style={styles.content}>Last Name: <Text style={styles.bold}>{userData.lastName}</Text></Text>
            <Text style={styles.content}>Country: <Text style={styles.bold}>{userData.country}</Text></Text>
            <Text style={styles.content}>Created At: <Text style={styles.bold}>{new Date(userData.createdAt).toLocaleDateString()}</Text></Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.content}>Document Type: <Text style={styles.bold}>{userData.idCard?.type}</Text></Text>
            <Text style={styles.content}>Document Issued Date: <Text style={styles.bold}>{new Date(userData.idCard?.issuedDate).toLocaleDateString()}</Text></Text>
            <Text style={styles.content}>Document valid until: <Text style={styles.bold}>{new Date(userData.idCard?.validUntil).toLocaleDateString()}</Text></Text>
            <Text style={styles.content}>Document number: <Text style={styles.bold}>{userData.idCard?.number}</Text></Text>
            <Text style={styles.content}>Document addtional number: <Text style={styles.bold}>{userData.idCard?.additionalNumber}</Text></Text>
            <Text style={styles.content}>Is Document shared: <Text style={styles.bold}>{userData.idCard?.shared? "Yes" : "No"}</Text></Text>
            <Text style={styles.content}>Date of Birth: <Text style={styles.bold}>{new Date(userData.idCard?.dob).toLocaleDateString()}</Text></Text>
          </View>

          <View style={styles.section}>
          <Text style={styles.heading}>1. Účel a plánovaná povaha transakcií vykonávaných na Platforme a finančné informácie</Text>
          <Text style={styles.content}>
            Q: Účel a plánovaná povaha transakcií vykonávaných na Platforme je:
          </Text>
          <Text style={[styles.content, {fontWeight:"bold"}]}>
            A: {userData.declaration.purposeOfTransactions}
          </Text>
          <View style={{ height: 7 }} />
          <Text style={styles.content}>
            Q: Predpokladaný celkový objem transakcií počas obdobia jedného mesiaca na Platforme je:
          </Text>
          <Text style={[styles.content, styles.bold]}>
            A: {userData.declaration.estimatedTotalVolumeofTransactions}
          </Text>
          <View style={{ height: 7 }} />
          <Text style={styles.content}>
            Q: Predpokladaná časová frekvencia vykonávania transakcií na Platforme je:
          </Text>
          <Text style={[styles.content, styles.bold]}>
            A: {userData.declaration.freqOfTransactions}
          </Text>
          <View style={{ height: 7 }} />
          <Text style={styles.content}>
            Q: Môj čistý ročný príjem je:
          </Text>
          <Text style={[styles.content, styles.bold]}>
            A: {userData.declaration.netAnnualIncome}
          </Text>
        </View>
        

        <View style={styles.section}>
          <Text style={styles.heading}>2. Konečný užívateľ výhod fyzickej osoby - podnikateľa</Text>
          <Text style={styles.content}>
            Q: som konečným užívateľom výhod:
          </Text>
          <Text style={[styles.content, styles.bold]}>
            A: {userData.declaration.isEndUser.toString()}
          </Text>
          <View style={{ height: 7 }} />

          {!userData.declaration.isEndUser && (
            <View>
              <Text style={styles.content}>
                Konečným užívateľom výhod (fyzická osoba, ktorá má právo na hospodársky prospech najmenej 25 % z podnikania fyzickej osoby – podnikateľa alebo z inej jej činnosti) je:
              </Text>
              <Text style={styles.content}>
                Meno a priezvisko: {userData.declaration.otherEndUser.fullName}
              </Text>
              <View style={{ height: 4 }} />
              <Text style={styles.content}>
                IČO / Dátum narodenia a rodné číslo: {userData.declaration.otherEndUser.birthNumber}
              </Text>
              <View style={{ height: 4 }} />
              <Text style={styles.content}>
                Adresa trvalého pobytu alebo iného pobytu: {userData.declaration.otherEndUser.address}
              </Text>
              <View style={{ height: 4 }} />
              <Text style={styles.content}>
                Štátna príslušnosť: {userData.declaration.otherEndUser.nationality}
              </Text>
            </View>
          )}
        </View>
        

        <View style={styles.section}>
          <Text style={styles.heading}>3. Politicky exponovaná osoba</Text>
          <Text style={styles.content}>
            Q: som politicky exponovanou osobou v zmysle AML zákona:
          </Text>
          <Text style={[styles.content, styles.bold]}>
            A: {userData.declaration.isPEP.toString()}
          </Text>
          <View style={{ height: 7 }} />
          {userData.declaration.isPEP && (
            <View>
              <Text style={styles.content}>
                Politicky exponovanou osobou v zmysle AML zákona som nasledovného dôvodu:
              </Text>
              {userData.declaration.PEPDetails && (
                <View>
                  <Text style={styles.content}>
                    {userData.declaration.PEPDetails.option !== "other" && userData.declaration.PEPDetails.option} {" " + userData.declaration.PEPDetails.subOption}
                  </Text>
                  {userData.declaration.PEPDetails.lessthan12M && (
                    <Text style={styles.content}>
                      bol som politicky exponovanou osobou pred menej ako 12 mesiacmi (v tomto prípade prosím zaškrtnite aj niektorú z možností uvedených vyššie odôvodňujúcich Vašu klasifikáciu ako politicky exponovanej osoby)
                      {userData.declaration.PEPDetails.lessthan12M.toString()}
                    </Text>
                  )}
                </View>
              )}
            </View>
          )}
        </View>
        

        <View style={styles.section}>
          <Text style={styles.heading}>4. Pôvod finančných prostriedkov</Text>
          <Text style={styles.content}>
            Q: finančné prostriedky alebo majetok použitý pri tomto obchode alebo obchodnom vzťahu sú mojím vlastníctvom:
          </Text>
          <Text style={[styles.content, styles.bold]}>
            A: {userData.declaration.isFundsMine.toString()}
          </Text>
          <View style={{ height: 7 }} />
          {!userData.declaration.isFundsMine && (
            <View>
              <Text style={styles.content}>
                Finančné prostriedky alebo majetok použitý pri tomto obchode alebo obchodnom vzťahu sú vo vlastníctve osoby:
              </Text>
              <Text style={styles.content}>
                Obchodné meno / Meno a priezvisko: {userData.declaration.fundsDetails.fullName}
              </Text>
              <Text style={styles.content}>
                IČO / Dátum narodenia a rodné číslo: {userData.declaration.fundsDetails.birthNumber}
              </Text>
              <Text style={styles.content}>
                Miesto podnikania, resp. sídlo / Adresa trvalého pobytu: {userData.declaration.fundsDetails.address}
              </Text>
            </View>
          )}
          <Text style={styles.content}>
            Q: Pôvod finančných prostriedkov - Finančné prostriedky alebo majetok použitý pri tomto obchode alebo obchodnom vzťahu pochádzajú z:
          </Text>
          <Text style={[styles.content, styles.bold]}>
            A: {userData.declaration.originOfFunds}
            </Text>
          </View>
          <View style={styles.section}>
          <Text style={styles.heading}>5. Konanie</Text>
          <Text style={styles.content}>
            Q: tento obchod vykonávam vo vlastnom mene:
          </Text>
          <Text style={[styles.content, styles.bold]}>
            A: {userData.declaration.conductingBusinessMyself.toString()}
          </Text>
          {!userData.declaration.conductingBusinessMyself && (
            <View>
              <Text style={styles.content}>
                Tento obchod vykonávam v mene osoby:
              </Text>
              <Text style={styles.content}>
                Obchodné meno / Meno a priezvisko: {userData.declaration.conductingBusinessDetails.fullName}
              </Text>
              <Text style={styles.content}>
                IČO / Dátum narodenia a rodné číslo: {userData.declaration.conductingBusinessDetails.birthNumber}
              </Text>
              <Text style={styles.content}>
                Miesto podnikania, resp. sídlo / Adresa trvalého pobytu: {userData.declaration.conductingBusinessDetails.address}
              </Text>
            </View>
          )}
          </View>
        </View>        
      </Page>
    </Document>
  );
};